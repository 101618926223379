import { Link } from "react-router-dom";

export const Splash = () => {
  const buttonStyle =
    "bg-white flex items-center justify-center font-anybody text-2xl hover:text-white hover:bg-black transition";
  return (
    <div className="w-screen h-screen bg-cover bg-bottom bg-main-bg flex items-center justify-center">
      <div className="flex gap-20">
        <Link className={`w-64 h-16 ${buttonStyle}`} to="/work">
          work
        </Link>
        <Link className={`w-48 h-16 ${buttonStyle}`} to="/profile">
          bio
        </Link>
      </div>
    </div>
  );
};
