//Renny Gong is in his fourth year at Columbia University. He loves to take things off the street.
import { Link } from "react-router-dom";

export const Profile = () => {
  const buttonStyle =
    "bg-black text-white flex items-center justify-center font-anybody text-sm hover:text-black hover:bg-white transition";
  return (
    <div className="w-screen h-screen flex items-center justify-center">
      <div className="flex gap-8 h-[500px]">
        <img
          src={`./images/profilePicture/profile.jpeg`}
          className="object-cover shrink-0 group-hover:opacity-20 transition"
          alt={"Renny drinking a Large Sangria"}
        />
        <div className="w-52 font-helvetica italic h-full flex grow-0 items-center text-xl">
          <div className="flex flex-col gap-2">
            <div>
              Renny Gong is in his fourth year at Columbia University. He loves
              to take things off the street.
            </div>
            <div className="flex flex-row gap-12">
              <Link className={`w-16 h-8 ${buttonStyle}`} to="/">
                home
              </Link>
              <Link className={`w-16 h-8 ${buttonStyle}`} to="/work">
                work
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
