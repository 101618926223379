import "./App.css";
import { Splash } from "./pages/Splash";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Work from "./pages/Work";
import { Profile } from "./pages/Profile";

function App() {
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Routes>
        <Route path="/" element={<Splash />}></Route>
        <Route path="/work" element={<Work />} />
        <Route path="/profile" element={<Profile />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
