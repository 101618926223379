import { useState } from "react";
import workData from "../data/work.json";
interface WorkElementProps {
  title: string;
  tagline: string;
  link: string;
  snippet: string;
  imageSlug: string;
}

const ElementColumn = ({
  data,
  setCurrentHover,
}: {
  data: WorkElementProps;
  setCurrentHover: (e: WorkElementProps | null) => void;
}) => {
  return (
    <a
      className=" shrink-0 relative flex flex-col justify-center bg-black group"
      onMouseEnter={() => setCurrentHover(data)}
      onMouseLeave={() => setCurrentHover(null)}
      href={data.link}
      target="_blank"
      rel="noreferrer"
    >
      <img
        src={`./images/workThumbnails/${data.imageSlug}`}
        className="w-44 h-full object-cover shrink-0 group-hover:opacity-20 transition"
        alt={data.imageSlug}
      />
      <div className="absolute px-4 my-auto mx-auto w-full text-white text-center font-helvetica tracking-tighter flex flex-col gap-8">
        <div className="text-xl drop-shadow-xl	">{data.title}</div>
        <div className="italic">{data.tagline}</div>
      </div>
    </a>
  );
};

const Work = () => {
  const [currentHover, setCurrentHover] = useState<WorkElementProps | null>(
    null
  );

  return (
    <div className="w-screen h-screen flex justify-center pr-16 pt-16">
      <div className="w-full h-full flex flex-row">
        <div className="h-full flex flex-col justify-end pb-8 sticky left-0 px-16 bg-white z-10">
          {currentHover ? (
            <a
              className="w-full h-full flex flex-col justify-center grow-0 items-center "
              href={currentHover.link}
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={`/images/workThumbnails/${currentHover.imageSlug}`}
                className="w-full object-contain bg-black"
                alt={currentHover.imageSlug}
              />
              <div className="font-helvetica tracking-tighter text-right w-full mt-1">
                {currentHover.title} in {currentHover.tagline}
              </div>
              <div className="font-helvetica tracking-tighter text-sm my-2 italic text-right">
                {currentHover.snippet}
              </div>
            </a>
          ) : null}

          <a
            className="text-right font-helvetica tracking-tighter text-xl px-4 flex flex-row justify-end gap-x-7 mb-1 z-10"
            href="/"
          >
            <div>renny</div>
            <div>gong</div>
          </a>
          <div className="font-helvetica font-bold tracking-tighter text-9xl leading-[2rem] mb-16 px-6">
            work
          </div>
        </div>
        <div className="w-full h-full shrink-0 flex flex-row gap-8">
          {workData.work.map((element) => {
            return (
              <ElementColumn data={element} setCurrentHover={setCurrentHover} />
            );
          })}
        </div>
      </div>

      {/* <div className="w-[550px] h-full mt-16 flex flex-col gap-4 ">
        <div className="font-soria text-6xl">Work</div>
        {workData.work.map((workElement, idx) => {
          return (
            <WorkDisplayElement data={workElement} polarity={idx % 2 === 0} />
          );
        })}
      </div> */}
    </div>
  );
};

export default Work;
